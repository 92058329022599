import React from 'react';
import styles from './_Hero.module.scss';
import NavBar from './NavBar/NavBar';

const Hero = () => {
	return (
		<div className={styles.hero}>
			<NavBar />
			<div className={styles.hero__header}>
			<h1 className={styles.header1}>Protocol Network</h1>
				<h1 className={styles.header2}>25+ Years of Automotive Excellence Right at Your Doorstep</h1>
				<p className={styles.subtext}>
				Colossians 3:23-24 
              
				</p>
			 
			</div>

		</div>
	);
};

export default Hero;
