import React from 'react';
import { Card, CardHeader, Button, Typography } from '@material-ui/core';
import './style.css';

function ContactForm() {
  const handleClick = () => {
    window.open("https://protocol-network.vercel.app/", "_blank");
  };

  return (
    <Card>
      <CardHeader title="Contact us" />
      <Typography variant="h6" gutterBottom>
        If you need a mobile mechanic for your vehicle, Protocol Network can provide you with fast and reliable service. Their team of experienced mechanics is equipped to handle a wide range of vehicle issues, from minor repairs to major overhauls.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Don't hesitate to get in touch with Protocol Network if you need help with your vehicle. Click the button below to visit their website and get started.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClick}>Open Protocol Network</Button>
    </Card>
  );
}

export default ContactForm;
