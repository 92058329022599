import React from 'react';
import styles from './_MapSection.module.scss';
 

const MapSection = () => {


	return (
		<section id="about" className={`${styles.main__container}`}>
			<div className={`container ${styles.mapsection__holder}`}>
				<div>
					<h1 className={styles.header}>Santa Maria, CA </h1>
				</div>
			 
			</div>
		</section >
	);
};

export default MapSection;
