import React, { useState } from 'react';
 
import './NavBar.css';

 
import MenuItems from './MenuItems';

import { Link } from 'react-scroll';

const NavBar = () => {
	const [clicked, setAsClicked] = useState(false);

	const toggle = (previous) => !previous;

	const handleClick = () => {
		setAsClicked(toggle);
	};

	return (
		<nav className="navbar-items">
			<span className="navbar-logo">
			<div> 
			<a class="social" href="https://www.facebook.com/PROTOCOLNETWORK">
    <i class="fa fa-facebook-f"></i>
  </a>
 
  <a class="social" href="https://www.instagram.com/xdrew2002x/">
    <i class="fa fa-instagram"></i>
  </a>	 
  </div>
			</span>
			<div className="menu-icon" onClick={handleClick}>
				{clicked ? (
					 <img src=" " alt="image" />
				) : (
					<img src=" " alt="image" />


 
				)}
			</div>
			<div>
			<a href="tel:8052687686" mypage="" class="call-now" rel="nofollow"><div
class="mypage-alo-phone"><div
class="animated infinite pulse mypage-alo-ph-circle-fill"></div><div
class="animated infinite tada mypage-alo-ph-img-circle"></div></div>
</a>
			</div>
			<ul className={clicked ? 'nav active' : 'nav'}>
				{MenuItems.map((item, index) => {
					return (
						<Link
							key={index}
							activeClass="active"
							to={item.scrollTo}
							spy={true}
							onClick={handleClick}
							smooth={true}
							offset={0}
							duration={500}
							className={item.cName}
						>
							{item.title}
						</Link>
						 
					);
				})}
			</ul>
		</nav>
	);
};

export default NavBar;
